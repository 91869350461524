import styled from "@emotion/styled"
import React from "react"
import useNewsletterSignup from "../../hooks/use-newsletter-signup"
import Link from "../base/link"
import Button from "../base/buttons"
import Input from "../base/input-field"
import InstagramIcon from "../../assets/icons/instagram.svg"

// FOOTER
const SiteFooter = styled.footer`
  background-color: ${({theme}) => theme.colors.darkGreen};
  color: ${({theme}) => theme.colors.white};
`

// CONTENT LAYOUT
const SiteFooterContentWrapper = styled.div`
  padding: 30px 15px;

  ${({ theme }) => theme.bp.desktop} {
    display: grid;
    grid-template-columns: 0.4fr 1.2fr 0.8fr 0.8fr 1.6fr;
    gap: 30px;
    padding: 60px 30px;
  }
`

const SiteFooterColumn = styled.div`
  margin-bottom: 30px;

  ${({ theme }) => theme.bp.desktop} {
    margin-bottom: 0;
  }
`

const SocialIcon = styled(InstagramIcon)`
  width: 24px;
  height: 24px;
  fill: ${({theme}) => theme.colors.white};
  transition: opacity 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

const SiteFooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const SiteFooterNavItem = styled.div`
  display: inline-block;
`

const SiteFooterNavLink = styled(Link)`
  color: ${({theme}) => theme.colors.white};
  text-decoration: none;
  transition: opacity 0.2s;
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  display: inline-block;

  &:hover {
    opacity: 0.8;
  }
`

const EmailText = styled.p`
  color: ${({theme}) => theme.colors.white};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
`

const NewsletterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const NewsletterHeading = styled.p`
  margin: 0;
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  text-transform: uppercase;
  letter-spacing: .06em;
`

const NewsletterInput = styled(Input)`
  background: transparent;
  border: 1px solid ${({theme}) => theme.colors.white};
  font-size: ${({theme}) => theme.fontSizes[2]}px !important;
  color: ${({theme}) => theme.colors.white};
  padding: 10px;
  min-width: 160px;
  max-width: 220px;

  &::placeholder {
    color: ${({theme}) => theme.colors.white};
    opacity: 0.8;
  }
`

const NewsletterButton = styled(Button)`
  background: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.darkGreen};
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: opacity 0.2s;
  white-space: nowrap;
  min-width: 80px;

  &:hover {
    opacity: 0.8;
  }
`

const NewsletterInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

const Footer = () => {
  const { handleEmailChange, handleSignup, signingUp, state } = useNewsletterSignup()

  return (
    <SiteFooter>
      <SiteFooterContentWrapper>
        <SiteFooterColumn>
          <a href="https://www.instagram.com/palmes.society/" target="_blank" rel="noreferrer">
            <SocialIcon />
          </a>
        </SiteFooterColumn>

        <SiteFooterColumn>
          <SiteFooterNav>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/contact">Contact</SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/terms-shipping-returns">Shipping + Returns</SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/about">About</SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/palmes-center-court">Stores</SiteFooterNavLink>
            </SiteFooterNavItem>
          </SiteFooterNav>
        </SiteFooterColumn>

        <SiteFooterColumn>
          <SiteFooterNav>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/privacy-policy">Privacy Policy</SiteFooterNavLink>
            </SiteFooterNavItem>
            <SiteFooterNavItem>
              <SiteFooterNavLink to="/careers">Careers</SiteFooterNavLink>
            </SiteFooterNavItem>
          </SiteFooterNav>
        </SiteFooterColumn>

        <SiteFooterColumn>
        </SiteFooterColumn>

        <SiteFooterColumn>
          <NewsletterSection>
            <NewsletterHeading>Newsletter</NewsletterHeading>
            {state.signupComplete ? (
              <EmailText>Thank you!</EmailText>
            ) : (
              <NewsletterInputWrapper>
                <NewsletterInput
                  placeholder="Enter your email"
                  onChange={handleEmailChange}
                  disabled={state.signupComplete}
                />
                <NewsletterButton
                  onClick={handleSignup}
                  disabled={state.signupComplete || signingUp}
                >
                  {signingUp ? "Joining..." : "Join"}
                </NewsletterButton>
              </NewsletterInputWrapper>
            )}
          </NewsletterSection>
        </SiteFooterColumn>
      </SiteFooterContentWrapper>
    </SiteFooter>
  )
}

export default Footer
